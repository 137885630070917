const topicSlug = {
  nasTym: "unser-team/",
  pripadoveStudie: "fallstudie/",
};

export const Content = {
  nasTym: {
    title: "Unser Team",
    slug: topicSlug.nasTym,
    description: `Sie werden ausschließlich mit zertifizierten Steuerberatern und qualifizierten Buchhaltern zusammenarbeiten, damit alle Ergebnisse korrekt und genau sind und Sie sich auf uns verlassen können. Gleichzeitig arbeitet das gesamte Team als Ganzes effektiv, nicht nur in Bezug auf die berufliche Spezialisierung, sondern auch in Bezug auf die Persönlichkeitsprofile der Mitarbeiter und die Betonung der Entwicklung von Soft Skills. Dadurch können Sie nicht nur perfekte Steuer- und Buchhaltungsergebnisse erwarten, sondern auch eine professionelle Herangehensweise und eine einfache Kommunikation.`,
    moreDE: true
  },
  pripadoveStudie: {
    title: "Fallstudie",
    slug: topicSlug.pripadoveStudie,
    description: `Wir haben Erfahrungen mit der Umsatzsteuerregistrierung von Hunderten Steuersubjekten, mit steuerlichen Lösungen bei der Restrukturalisierung, bei der Implementierung von ERP-Systemen (Geschäftsressourcenplanung) und bei der Analyse von Verrechnungspreisen. In „Fallstudien“ stellen wir Ihnen konkrete Projekte und ihre Ergebnisse vor.`,
    moreDE: true
  },
};
export default Content;
