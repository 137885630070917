import React from 'react'
import Layout from '../../../components/DE/LayoutDE'
import PageHeader from '../../../components/PageHeader/PageHeader'
import '../../../components/ParagraphUnderPhoto/styles.css'
import Accordion from '../../../components/Accordion/Accordion'
import AccordionTemplate from '../../../components/Services/AccordionTemplate'
import Content from '../../../../content/accordion/de/o-nas'
import { graphql } from 'gatsby'
import { H2Blue, UnderlineYellow } from '../../../components/Typography'
const Onas = ({ data: { page } }) => {
  const { frontmatter } = page
  return (
    <Layout
      meta={page.frontmatter.meta || false}
      title={page.frontmatter.title || false}
    >
      <PageHeader
        title={frontmatter.title}
        backgroundImage={frontmatter.image.childImageSharp.fluid}
      />

      <div className="Paragraph--Wrapper container">
        <p>{frontmatter.description}</p>
        <H2Blue>Kunden</H2Blue>
        <UnderlineYellow />
        <p style={{ marginTop: '5.8rem' }}>
          Unsere Mandanten sind hauptsächlich kleine und mittlere juristische
          Personen, für die das Outsourcing von Buchhaltungs- und
          Steuerdienstleistungen die effektivste Lösung ist. Wir suchen auch
          nach einer Zusammenarbeit mit Start-ups, um sie von Buchhaltung und
          Steuerverwaltung zu entlasten, bei der Einrichtung von Prozessen zu
          helfen und es Ihnen zu ermöglichen, sich ausschließlich auf die
          Geschäftsentwicklung zu konzentrieren. Wir verarbeiten alles präzise
          und effizient. Dies wird auch durch fortschrittliche Software mit
          einer Cloud-Lösung unterstützt, mit der Sie unter anderem die
          Verwaltung von der Buchhaltung trennen können und der Export aller
          Daten einfach und klar ist.
        </p>
        <Accordion
          items={[
            {
              title: Content.nasTym.title,
              description: <AccordionTemplate {...Content.nasTym} />,
            },
            {
              title: Content.pripadoveStudie.title,
              description: <AccordionTemplate {...Content.pripadoveStudie} />,
            },
          ]}
        />
      </div>
    </Layout>
  )
}

export default Onas

export const pageQuery = graphql`
  query OnasDE {
    page: markdownRemark(frontmatter: { slug: { eq: "de/uber-uns" } }) {
      ...Meta
      frontmatter {
        title
        description
        image {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
